"use client";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";

export default function GlobalError({ error }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
      <h1>An error occurred</h1>
      <p>{error.message}</p>
      {error.stack && <pre>{error.stack}</pre>}
        {/*<Error />*/}
      </body>
    </html>
  );
}
